import React, { useState } from 'react'
import {
    Button,
    Grid,
    Box,
    TextField,
    CircularProgress,
    Card,
} from '@mui/material';
import { userCredentials } from '../../../../utilities/config';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import swal from 'sweetalert';
import axios from 'axios';
import CountdownTimer from './TimerCountdown';
import moment from 'moment';
import crypto from 'crypto-js'; 


const UserFormProfile : React.FC<any> = () => {

    const [openDialog, setOpenDialog] = React.useState(false);
    const [reset, setReset] = useState(false);
    const [loading, setLoading] = useState(false);
    const [codeOTP, setCodeOTP] = useState<any>("")
    const [number, setNumber] = useState<any>("")

    const handleOpenDialog = () => {
        setCodeOTP("")
        setOpenDialog(true);
      };
    
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    async function sendMessageOTP() {
        setLoading(true)
        const body = {
            "to": `${number}`,
            "type": "template",
            "template_type": "dynamic",
            "template_name": "otp",
            "language_code": "id",
            "url_call_to_action": "groperti"
        }
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_GROCHAT}/message`, body, {
                headers: {
                  'Authorization' : `Bearer ${userCredentials?.token}`,
                }
            })
            if(response.status >= 200) {
                setReset(!reset)
                handleOpenDialog()
                setLoading(false)
            }
          } catch (error : any) {
            swal('error', `${error?.response?.errors}`, 'Error' )
            setLoading(false)
        }
    }

    async function registerInfluencer(params:any) {
        setLoading(true)
        try {
            try {
                const influencer : any = await axios.post(`${process.env.REACT_APP_API_DEV}/influencer`, params, {
                    headers: {
                        'Authorization' : `Bearer ${userCredentials.token}`
                    }
                })
                if(influencer.status === 201) {
                    swal('Success', `Nomor sukses di verifikasi.`, 'success')
                    const updateCredential = {...userCredentials, first_time  : false, code_referral : influencer?.data?.data?.referral_code}
                    const saveToLocalStorage = crypto.AES.encrypt(JSON.stringify(updateCredential), `${process.env.REACT_APP_CRYPTO_SECRET}`).toString();
                        localStorage.setItem('_?GroRefcredentials', saveToLocalStorage)
                        setTimeout(() => {
                            window.location.href = "/dashboard"
                    }, 1000);
                } else {
                    swal('Error', `Ada sesuatu yang salah! Coba beberapa menit lagi!`, 'Error' )
                    setLoading(false)
                }
            } catch (error:any) {
                swal('Error', `${error?.response?.errors}`, 'Error' )
            }
          } catch (error : any) {
            swal('Error', `${error?.response?.errors}`, 'Error' )
            setLoading(false)
        }
    }




    async function onClickVerifikasi(e:any) {
        e.preventDefault()
        setLoading(true)
        const time = moment().format()
        const body = {
            "number": `${number}`,
            "otp": `${codeOTP}`,
            "time": `${time}`
        }
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_GROCHAT}/otp/check-result`, body, {
                headers: {
                    'Authorization' : `Bearer ${userCredentials?.token}`,
                }
            })
            if(response.status >= 200) {
                if(response.data.code === 200) {
                    setLoading(false)
                    let body_send  = {
                        phone_number : number,
                        auth_id : userCredentials.auth_id,
                    }
                    registerInfluencer(body_send)
                } else {
                    setLoading(false)
                    swal('Error', `Kode OTP salah! Silahkan coba lagi dan pastikan nomor terdaftar di Whatsapp."`, 'error' )
                }
            }
          } catch (error : any) {
            swal('error', `${error?.response?.errors}`, 'Error' )
            setLoading(false)
        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        sendMessageOTP()
    }


    return (
        <div>
            <div className="section-form-company-detail">
                <Box>
                    <form onSubmit={handleSubmit}>
                        <Card elevation={3} sx={{p:3}}> 
                            <Grid container spacing={2} mb={2}>
                                <Grid item xl={6} lg={6} xs={12}>
                                    <Box className='title-label'>
                                        No Whatsapp
                                    </Box>
                                    <Box>
                                        <TextField
                                            margin="dense"
                                            fullWidth
                                            type="number"
                                            placeholder="Masukan Nomor Whatsapp"
                                            value={number}
                                            onChange={(e:any) => setNumber(e.target.value)}
                                            name="phone_number"
                                            size="small"
                                            required
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Button 
                                variant="contained"
                                type="submit"
                                size='small'
                            >
                                { loading ? <span><CircularProgress color='inherit' size={20} /> Loading</span> : "Kirim Kode OTP" }
                            </Button>
                        </Card>

                    </form>
                </Box>
            </div>

            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <form onSubmit={onClickVerifikasi}>
                    <DialogTitle>Kode OTP</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                       Silahkan masukan kode otp yang kami kirimkan ke whatsapp kamu.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="normal"
                        id="name"
                        label="Kode otp"
                        fullWidth
                        variant="outlined"
                        required
                        size='small'
                        type='number'
                        value={codeOTP} 
                        onChange={(e:any) => setCodeOTP(e.target.value)}
                    />
                    <Button className='mr-2' onClick={sendMessageOTP}>Kirim Ulang</Button>
                    <Box ml={2}><CountdownTimer reset={reset} /></Box>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" variant="contained">
                            { loading ? <span><CircularProgress color='inherit' size={20} /> Loading</span> : "Submit" }
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}

export default UserFormProfile

