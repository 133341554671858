import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AccountPage from '../features_app/dashboard/account/AccountPage';
import HomeDashboard from '../features_app/dashboard/home/HomeDashboard';
import ProfilePage from '../features_app/dashboard/profile/ProfilePage';
import DashboardLayout from '../utilities/DashboardLayout';
import SettingPage from '../features_app/dashboard/setting/SettingPage'
import LoginPage from '../features_app/auth/login/LoginPage';
import RegisterPage from '../features_app/auth/register/RegisterPage';
import ErrorPage from '../features_app/error404/ErrorPage';
import ForgotPage from '../features_app/auth/forgot/ForgotPage';
import HomePage from '../features_app/landing/HomePage';
import WithdrawPage from '../features_app/dashboard/withdraw/WithdrawPage';
import StatistikPage from '../features_app/dashboard/statistik/StatistikPage';
import ProductPage from '../features_app/dashboard/products/ProductsPage';
import CompleteRegister from '../features_app/dashboard/complete_register/CompleteRegister';

function AppRoute() {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/register" component={RegisterPage} />
          <Route exact path="/forgot-password" component={ForgotPage} />
          <DashboardLayout exact path="/dashboard" component={HomeDashboard} />
          <DashboardLayout exact path="/dashboard/propertyshared" component={ProductPage} />
          <DashboardLayout exact path="/completing-register" component={CompleteRegister} />
          <DashboardLayout exact path="/dashboard/withdraw" component={WithdrawPage} />
          <DashboardLayout exact path="/dashboard/statistik" component={StatistikPage} />
          <DashboardLayout exact path="/dashboard/profile" component={ProfilePage} />
          <DashboardLayout exact path="/dashboard/account" component={AccountPage} />
          <DashboardLayout exact path="/dashboard/setting" component={SettingPage} />
          <Route exact path="*" component={ErrorPage} />

        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default AppRoute;
