import { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { Box } from '@mui/system'
import { TableColumn } from 'react-data-table-component';
import BreadCrumbs from '../../../components/BreadCrumbs'
import DataTableBase from '../../../components/TableData'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { getAllProperty } from './reducers/productsReducers';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { userCredentials } from '../../../utilities/config';

const ProductPage = () => {

    const dispatch = useDispatch()
    const state_products = useSelector((state : RootState) => state.products)

    const [limit, setLimit] = useState(15);
    const [page, setPage] = useState(1);


    const [openView, setOpenView] = useState(false);

    const handleCloseView = () => {
        setOpenView(false);
    };

    useEffect(() => {
        dispatch(getAllProperty())
        // eslint-disable-next-line
    }, []);

    const columns: TableColumn<any>[] = [
        {
            name: 'NO',
            width: '70px',
            cell: (row, index) => (
                <p>
                { page > 1 ? 
                    <>
                    {index + 1 + (limit * (page-1))}
                    </>
                    :
                    <> 
                    {index + 1}
                    </>
                }
                </p>
            )
        },
        {
            name: 'NAME',
            width: '350px',
            cell: (row) => (
                <Box pl={1} pt={1} sx={{ textTransform : 'capitalize', color: 'blue' }}>
                    <a href={`https://groperti.com/detail/${row.house_detail.slug}`} rel="noreferrer" target="_blank">{row.house_detail.title}</a>
                </Box>
            )
        },
        {
            name: 'PRICE',
            cell: (row) => (
                <p>Rp. {row.house_detail.price.title}</p>
                
            )
        },
        {
            name: 'Type Property',
            cell: (row) => (
                <Box sx={{ textTransform : 'capitalize' }} >{row.house_detail.type} di {row.house_detail.sell_type}</Box>
            )
        },
        {
            name: 'Total Reward',
            cell: (row) => (
                <p>Rp. {row.house_detail.shared_max_rupiah.toLocaleString()}</p>
            )
        },
        {
            name: 'Viewer',
            cell: (row) => (
                <p>{row.clicked_times}</p>
            )
        },
        {
            name: 'Reward per Click',
            cell: (row) => (
                <p>Rp. {row.total_rupiah.toLocaleString()}</p>
            )
        },
        {
            name: 'Status Property',
            cell: (row) => (
                <Box>
                    { row.house_detail.status === "SOLD" && row.house_detail.soldByReferral === userCredentials.code_referral ? 
                    <p>{row.house_detail.status} by You</p> : row.house_detail.status === "SOLD" && row.house_detail.soldByReferral !== userCredentials.code_referral ? 
                    <p>{row.house_detail.status} by Other</p> : <p>{row.house_detail.status}</p> }
                </Box>
            )
        },
        // {
        //     name: 'Sold By',
        //     cell: (row) => (
        //         <Box>
        //             Others / Groperti
        //         </Box>
        //     )
        // },
    ];

    return (
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs
                isPage={false}
                current="Properti Dibagikan"
            />
            <Stack direction="row" justifyContent="space-between" pt={3} >
                <Box>
                    <h2>Properti Dibagikan</h2>
                </Box>
            </Stack>

            <Box sx={{pt:3}}>
                <DataTableBase 
                    columns={columns}
                    data={state_products.data}
                    progressPending={state_products?.loading}
                    pagination
                    onChangePage={(value) => setPage(value)}
                    onChangeRowsPerPage={(value) => setLimit(value)}
                    paginationPerPage={limit}
                    persistTableHead
                    noDataComponent="Beluma ada data! Yuk mulai bagikan property untuk mendapatkan uang."
                />
            </Box>

            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={openView}
                onClose={handleCloseView}
            >
                <DialogTitle>
                    <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Box>Statistik Properti</Box>
                        <Box>
                            <CloseIcon sx={{ color: 'red', cursor: 'pointer', fontSize: 30 }} onClick={handleCloseView}/>
                        </Box>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Stack pb={2} >
                    <Box>
                        Total View  : 50
                    </Box>
                    <Box>
                        Total Reward  : Rp. 5.000
                    </Box>
                    </Stack>
                    
                </DialogContent>
            </Dialog>

        </Box>
    )
}

export default ProductPage
