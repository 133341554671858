import React, { useState, useEffect } from 'react';

const CountdownTimer: React.FC<any> = () => {
  const initialTime = 600; // 10 minutes in seconds
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const updateTimer = () => {
      setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    };

    intervalId = setInterval(updateTimer, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);


  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  return (
    <span className="text-red-500">{formatTime(time)}</span>
  );
};

export default CountdownTimer;
