import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios'

export const registerAction = createAsyncThunk(
    'auth/register',
    async (value : any , { rejectWithValue }) => {
      try {
          const body = {
            email : value.email,
            password : value.password,
            name : value.name,
            phone_number : value.phone_number,
            flag : ["INFLUENCER"],
          }
          const response : any = await Axios.post(`${process.env.REACT_APP_API_AUTH}/api/register`, body)
          if(response.status === 200) {
            return true
          } else {
            return rejectWithValue("Akun sudah terdaftar, silahkan daftar dengan email lain")
          }
        } catch (err : any) {
          if (!err.response) {
            throw err
          }
          return rejectWithValue(err.response.data.errors || "Internal server error")
      }
    }
  );


  

/* istanbul ignore file */
