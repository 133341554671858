import { Grid, Box } from '@mui/material'
import share from '../../../assets/img/sosmed.png';


const Jumbroton = () => {
  return (
    <div className='headline-container'>
        <Grid container>
            <Grid item xl={8} lg={8} md={6} sm={12} xs={12}>
                <h1>Bagikan Properti, Dapat Uang Tanpa Batas </h1>
                <h6>GroReferal menggunakan aplikasi keuangan untuk lebih mudah menghasilkan uang dengan membagikan properti.</h6>

                <Box className='btn-listing' onClick={() => window.location.href = "/register"}>
                   <p>Daftar Sekarang</p>
                </Box>
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                <Box
                    sx={{ float: 'right', display: {xs: 'none', sm: 'none', md: 'block' } }}
                >
                    <img src={share} alt="sale"/>
                </Box>
            </Grid>
        </Grid>
    </div>
  )
}

export default Jumbroton