import { Container, Stack } from '@mui/material'
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import { Action } from './components/Action'
import { Faq } from './components/Faq'
import Jumbroton from './components/Jumbroton'
import { Step } from './components/Step'


function HomePage() {
    return (
        <Stack>
            <Stack 
                pt={2} pb={2}
                sx={{ backgroundColor: '#2d2a6d'}}
            >
                <Container maxWidth="lg" sx={{ display: {xs: 'none', sm: 'none', lg: 'none', xl: 'block' }}}>
                    <Navbar/>
                </Container>
                <Container fixed sx={{ display: { lg: 'block', xl: 'none' }}}>
                    <Navbar/>
                </Container>
            </Stack>
           
            <Stack 
            >
                <Container maxWidth="lg" sx={{ display: {xs: 'none', sm: 'none', lg: 'none', xl: 'block' }}}>
                    <Jumbroton/>
                </Container>
                <Container fixed sx={{ display: { lg: 'block', xl: 'none' }}}>
                    <Jumbroton/>
                </Container>
            </Stack>
            <Stack 
                sx={{ backgroundColor: '#e8f0f5' }}
            >
                <Container maxWidth="lg" sx={{ display: {xs: 'none', sm: 'none', lg: 'none', xl: 'block' }}}>
                    <Step/>
                </Container>
                <Container fixed sx={{ py:5, display: { lg: 'block', xl: 'none' }}}>
                    <Step/>
                </Container>
            </Stack>
            <Stack 
            >
                <Container maxWidth="lg" sx={{ display: {xs: 'none', sm: 'none', lg: 'none', xl: 'block' }}}>
                    <Faq/>
                </Container>
                <Container fixed sx={{ py:1, display: { lg: 'block', xl: 'none' }}}>
                    <Faq/>
                </Container>
            </Stack>
            <Stack 
               
                sx={{ backgroundColor: '#e8f0f5' }}
            >
                <Container maxWidth="lg" sx={{ display: {xs: 'none', sm: 'none', lg: 'none', xl: 'block' }}}>
                    <Action/>
                </Container>
                <Container fixed sx={{ py:1, display: { lg: 'block', xl: 'none' }}}>
                    <Action/>
                </Container>
            </Stack>
            <Stack
                pt={2} pb={2}
                sx={{ backgroundColor: '#2d2a6d'}}
            >
                <Container maxWidth="lg" sx={{ display: {xs: 'none', sm: 'none', lg: 'none', xl: 'block' }}}>
                    <Footer/>
                </Container>
                <Container fixed sx={{ display: { lg: 'block', xl: 'none' }}}>
                    <Footer/>
                </Container>
            </Stack>
        </Stack>
    )
}

export default HomePage
