import { Stack } from '@mui/material'
import { Box } from '@mui/system'
import {userCredentials} from '../../../utilities/config'
import UserFormProfile from './components/UserFormProfile'

const username = userCredentials === null ? "" : userCredentials.name

function CompleteRegister() {

    return (
        <Box sx={{pl:3, pr:3, pt:2}}>
            <Box fontWeight="500" fontSize="20px">Halo, {username}</Box>
           <Box fontWeight="400" fontSize="15px">
            Terima Kasih sudah mendaftar di Referal Groperti. Kami perlu mem-verifikasi nomor whatsapp kamu untuk melanjutkan.</Box>
            <Stack sx={{pt:3}} >
            <Box>
                <UserFormProfile
                    profile={false}
                />
            </Box>
            </Stack>
        </Box>
    )
}

export default CompleteRegister
