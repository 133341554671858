import { Box, Stack } from '@mui/material'
import logo from '../assets/img/logo.png'
import { Link } from 'react-router-dom';
import ButtonOutlined from './ButtonOutlined';
import ButtonContained from './ButtonContained';

function NavbarApp() {

    return (
        <Stack pt={1} pb={1}>
            <Stack flexDirection="row" justifyContent="space-between">
                <div className='logo-groperti'>
                    <a href="/">
                        <img src={logo} alt="logo" />
                    </a>
                </div> 
                <Stack flexDirection="row" justifyContent='space-between'>
                    <Box>
                        <Link to="/register">
                            <ButtonOutlined 
                                name="Daftar" 
                                size="16"
                                color="#fff"
                                contrast="#fff"
                            />
                        </Link>
                    </Box><Box>
                        <Link to="/login">
                            <ButtonContained 
                                name="Masuk" 
                                size="16"
                                color="#fff"
                                contrast="#2d2a6d"
                                />
                        </Link>
                    </Box> 
                </Stack>
                
            </Stack>
        </Stack>
    )
}

export default NavbarApp
