import { useEffect, useState } from 'react';
import {
    Container,
    CssBaseline,
    Box,
    TextField,
    Button, 
    Grid, 
    Link, 
    CircularProgress, 
    Stack
} from '@mui/material';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../app/store';
import { loginAction, loginGoogleAction } from './reducers/loginReducers';
import { UserFormSubmit } from './loginTypes'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { GoogleLogin } from '@react-oauth/google';

const validationSchema = yup
  .object({
    email: yup.string()
      .required("Alamat Email tidak boleh kosong")
      .email("Alamat Email is invalid"),
    password: yup.string()
      .required("Kata Sandi tidak boleh kosong")
  })
  .required();


const Login = () => {
  const dispatch = useDispatch()
  const auth = useSelector((state : RootState) => state.login )

  // console.log(auth, 'authh')

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<UserFormSubmit>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema)
  });
  
  const onSubmit = (data: UserFormSubmit): void => {
    dispatch(loginAction(data))
  }

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  
  useEffect(() => {
    if(auth.login && auth.data?.first_time) {
      setTimeout(() => {
        window.location.href = '/completing-register'
      }, 1000);
    } else if (auth.login && !auth.data?.first_time) {
      setTimeout(() => {
        window.location.href = '/dashboard'
      }, 1000);
    }
    // eslint-disable-next-line
  }, [auth.login]);

  
  return (
    <>
      <div className='container-auth'>
      <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 12,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box color="secondary">
              <Stack flexDirection="column" alignItems="center">
              <Box className='title-daftar'>
                <h1>Masuk Referal Groperti</h1>
                <p>Silahkan masukan data akun kamu.</p>
              </Box>
              </Stack>
            </Box>

            <Box className='container-form'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box className='title-label'>Email</Box>
              <TextField
                error={!!errors.email}
                helperText={errors.email && errors.email.message}
                {...register('email', { required: true })}
                margin="dense"
                fullWidth
                id="email"
                placeholder="your@email.com"
                name="email"
                autoComplete="email"
                size='small'
              />
              <Box className='title-label'>Kata Sandi</Box>
              <TextField
                error={!!errors.password}
                helperText={errors.password && errors.password.message}
                {...register('password', { required: true })}
                margin="dense"
                fullWidth
                name="password"
                placeholder='*******'
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment : (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                id="password"
                autoComplete="current-password"
                size='small'
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                size="medium"
                color="primary"
              >
                Masuk
                
                { 
                  auth.loading && <CircularProgress  size={30} color="inherit" style={{marginLeft: 10}} /> }
              </Button>

              <Box style={{display:'flex', justifyContent: 'center', width: '100%'}} pb={2}>
                <p>atau</p>
              </Box>
              <Box style={{display:'flex', justifyContent: 'center', width: '100%'}}> 

              <GoogleLogin
                onSuccess={credentialResponse => {
                  dispatch(loginGoogleAction(credentialResponse?.credential || ""))
                }}
                onError={() => {
                  window.location.reload()
                }}
                width="350"
                logo_alignment='left'
                theme='filled_blue'
                size='large'
                auto_select={false}
              />
             </Box>
              <Grid container justifyContent="space-between" mt={2}>
                <Grid item>
                  {"Belum punya akun? "}
                  <Link href="/register">
                    Daftar
                  </Link>
                </Grid> 
                <Grid item>
                  <Link href="/forgot-password">
                    Lupa password?
                  </Link>
                </Grid>
              </Grid>
            </form>
            </Box>
          </Box>
        </Container>
      </div>
    </>
    )
}

export default Login;
