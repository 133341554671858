import { useEffect, useState } from 'react';
import {
    Stack,
    Container,
    CssBaseline,
    Box,
    TextField,
    Button, 
    Grid, 
    Link, 
    CircularProgress 
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../app/store';
import { registerAction } from './reducers/registerReducers';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { GoogleLogin } from '@react-oauth/google';
import { loginGoogleAction } from '../login/reducers/loginReducers';

const Register = () => {
  const dispatch = useDispatch()
  const register_store = useSelector((state : RootState) => state.register )
  const auth = useSelector((state : RootState) => state.login )

  const [data, setData] = useState({
    email: "",
    password : "",
    name : "",
    phone_number : "",
    flag : "AGENT"
  });


  const onSubmit = (event: any): void => {
    event.preventDefault()
    dispatch(registerAction(data))
  }

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  function onChange(e:any) {
    setData({...data, [e.target.name] : e.target.value})
  }

   useEffect(() => {
    if(register_store.register) {
      setTimeout(() => {
          window.location.href = "/login"
        }, 2500);
    }
  }, [register_store.register]);

  
  useEffect(() => {
    if(auth.login && auth.data?.first_time) {
      setTimeout(() => {
        window.location.href = '/completing-register'
      }, 1000);
    } else if (auth.login && !auth.data?.first_time) {
      setTimeout(() => {
        window.location.href = '/dashboard'
      }, 1000);
    }
    // eslint-disable-next-line
  }, [auth.login]);

  
  return (
    <>
       
      <div className='container-auth'>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box color="secondary">
              <Stack flexDirection="column" alignItems="center">
              
              <Box className='title-daftar'>
              <h1>Daftar Referal Groperti</h1>
              <p>Silahkan masukan data diri Anda.</p>
              </Box>
              </Stack>
            </Box>

            <Box className='container-form'>
              <form onSubmit={onSubmit}>
                <Box className='title-label'>Nama Lengkap</Box>
                <TextField
                  value={data.name}
                  onChange={onChange}
                  margin="dense"
                  fullWidth
                  id="name"
                  placeholder="John Doe"
                  name="name"
                  size='small'
                  required
                />
                <Box className='title-label'>Email</Box>
                <TextField
                  value={data.email}
                  onChange={onChange}
                  margin="dense"
                  fullWidth
                  id="email"
                  placeholder='your@email.com'
                  name="email"
                  autoComplete="email"
                  size='small'
                  required
                />

                <Box className='title-label'>Kata Sandi</Box>
                <TextField
                  value={data.password}
                  onChange={onChange}
                  margin="dense"
                  fullWidth
                  name="password"
                  placeholder='********'
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  autoComplete="current-password"
                  size='small'
                  InputProps={{
                    endAdornment : (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  required
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  size="medium"
                  color="primary"
                > 
                  Daftar
                  
                  { 
                    register_store.loading && <CircularProgress  size={30} color="inherit" style={{marginLeft: 10}} /> }
                </Button>

                <Box style={{display:'flex', justifyContent: 'center', width: '100%'}} pb={2}>
                  <p>atau</p>
                </Box>

                <Box style={{display:'flex', justifyContent: 'center', width: '100%'}} mb={2}> 
                  <GoogleLogin
                    onSuccess={credentialResponse => {
                      dispatch(loginGoogleAction(credentialResponse?.credential || ""))
                    }}
                    onError={() => {
                      window.location.reload()
                    }}
                    width="350"
                    logo_alignment='left'
                    theme='filled_blue'
                    size='large'
                    auto_select={false}
                  />
                  
                </Box>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    {"Sudah punya akun? "}
                    <Link href="/login">
                      Masuk
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        </Container>
      </div>
    </>
    )
}

export default Register;
