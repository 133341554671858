import { useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import {
  InstantSearch,
  RefinementList,
  Pagination,
  ClearRefinements,
  Hits,
  HitsPerPage,
  Panel,
  Configure,
  SearchBox,
  Stats,
  Highlight
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import {
  ClearFiltersMobile,
  NoResults,
  ResultsNumberMobile,
  SaveFiltersMobile,
} from './components/widgets';
import './components/widgets/Theme.css';
import './components/widgets/App.css';
import './components/widgets/App.mobile.css';
import './components/widgets/Pagination.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import copyimage from '../../assets/img/copy.png'

import { Stack, Box } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility';
import ButtonContained from '../ButtonContained';
import { useLocation } from 'react-router-dom';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, TelegramShareButton } from "react-share";
import { FacebookIcon, TwitterIcon, WhatsappIcon, TelegramIcon } from "react-share";
import axios from 'axios';
import { userCredentials } from '../../utilities/config';
import swal from 'sweetalert';

const searchClient = algoliasearch(
  'UKY8L1R4NC',
  'e2709bc6f3fe61f1da6d3e442efa6b2a'
);



const AlgoliaSearch = (props) => {
  const containerRef = useRef(null);
  const headerRef = useRef(null);
  const location = useLocation()

  function useQuery() {
      return new URLSearchParams(location.search);
  }
  
  let query = useQuery();
  let search = query.get('search')

  const [openShare, setOpenShare] = useState(false);
  const [loadingShare, setLoadingShare] = useState(false);
  const [indexHit, setIndexHit] = useState();

  const handleCloseShare = () => {
    setOpenShare(false);
  };

  const handleClickOpen = async (hit) => {
    setIndexHit(hit.slug)
    setLoadingShare(true)
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_DEV}/house-list/share/${hit.slug}/${userCredentials.code_referral}`)
      // await axios.get(`${process.env.REACT_APP_API_DEV}/house-list/${hit.slug}/${userCredentials.code_referral}`)
      if(response.data) {
        setShareTitle(hit.title)
        setShareUrl(hit.slug)
        setSharePrice(hit.price.title)
        setOpenShare(true);
        setLoadingShare(false)
      } else {
        swal('error', `${response.data.message}`, 'error')
        setLoadingShare(false)
      }
    } catch (error) {
      swal('error', `${error}`, 'error')
      setLoadingShare(false)
    }
  };

  

  function openFilters() {
    document.body.classList.add('filtering');
    window.scrollTo(0, 0);
    window.addEventListener('keyup', onKeyUp);
    window.addEventListener('click', onClick);
  }

  function closeFilters() {
    document.body.classList.remove('filtering');
    containerRef.current.scrollIntoView();
    window.removeEventListener('keyup', onKeyUp);
    window.removeEventListener('click', onClick);
  }

  function onKeyUp(event) {
    if (event.key !== 'Escape') {
      return;
    }

    closeFilters();
  }

  function onClick(event) {
    if (event.target !== headerRef.current) {
      return;
    }

    closeFilters();
  }

  const [copyTrue, setCopyTrue] = useState(false);

  const [shareTitle, setShareTitle] = useState("");
  const [shareUrl, setShareUrl] = useState("");
  const [sharePrice, setSharePrice] = useState("");


  const Hit = ({ hit }) => (
    <Stack
        sx={{
            border: '1px solid #ccc',
            borderRadius: 2
        }}
    >   
        <div className='image-card-box-algolia'>
            <div className="zoom-effect-container">
                <Stack flexDirection="row" justifyContent="flex-end"  >
                    <Box className="view-tite">
                        <VisibilityIcon sx={{ fontSize: 17, mr: 1 }}/>
                        <Box fontWeight="300">{hit.total_view}</Box>
                    </Box>
                </Stack>
                <a href={`https://groperti.com/detail/${hit.slug}`} target="_blank" rel="noreferrer">
                    <div className="image-card">
                        <img alt="rumah" src={hit.images[0]} />
                    </div>
                </a>
            </div>
        </div>
        
        <Stack p={2}>
            <div className='title-price-algolia'>
                <Stack flexDirection="row"  alignItems="center">
                    <Box><h1>Rp. {hit.price.title}</h1></Box>
                    {/* { hit.indicator ?
                    <Stack pl={1} flexDirection="row" alignItems="center">
                        <CheckCircleIcon sx={{ color : '#2d2a6d', paddingRight: '4px', fontSize: '20px' }} />
                    </Stack> : null } */}
                </Stack>
                {/* { hit.indicator ?
                <h3>Rp. {hit.overPrice.title}</h3> :
                <h4>Rp. {hit.overPrice.title}</h4> } */}
                <h5 className='font-bold text-green-600'>Reward : Rp. {hit.shared_max_rupiah && hit.shared_max_rupiah.toLocaleString()}</h5>
                <p>{hit.facilities.bedroom} KT, {hit.facilities.bathroom} KM, {hit.facilities.building_area} LB, {hit.facilities.land_area} LT</p>
            </div>
            <div className='title-name-algolia'>
                {/* <h4>{hit.title}</h4> */}
                <Highlight attribute="title"  hit={hit} />
            </div>
            {/* <div className='title-address'>
                <h5>{hit.area.city}, {hit.area.province}</h5>
            </div> */}
            <Stack pt={2} pb={1} >
                <Box 
                  onClick={() => {
                    handleClickOpen(hit)
                  }}
                >
                    <ButtonContained 
                        name={loadingShare && indexHit === hit.slug ? "Loading.." : "Bagikan Properti"}
                        color="#2d2a6d"
                        contrast="#fff"
                    />
                </Box>
            </Stack>
        </Stack>
    </Stack>
  );


  return (
    <>
    <InstantSearch
      searchClient={searchClient}
      indexName="house_listing"
      searchState={props.searchState}
      createURL={props.createURL}
      onSearchStateChange={props.onSearchStateChange}
    >
      {/* <header className="header" ref={headerRef}>
        <Box pb={2}>
          <p className="header-title">Cari rumah atau apartemen</p>
        </Box>
      </header> */}
      <SearchBox
        translations={{
          placeholder: 'Rumah 2 lantai di Kota Jakarta Utara …',
        }}
        defaultRefinement={search}
      />

      <Configure
        attributesToSnippet={['description:10']}
        snippetEllipsisText="…"
        removeWordsIfNoResults="allOptional"
      />

      <main className="container" ref={containerRef}>
        <div className="container-wrapper">
          <section className="container-filters" onKeyUp={onKeyUp}>
            <div className="container-header">
              <h2>Filters</h2>

              <div className="clear-filters" data-layout="desktop">
                <ClearRefinements
                  translations={{
                    reset: (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                        >
                          <g fill="none" fillRule="evenodd" opacity=".4">
                            <path d="M0 0h11v11H0z" />
                            <path
                              fill="#000"
                              fillRule="nonzero"
                              d="M8.26 2.75a3.896 3.896 0 1 0 1.102 3.262l.007-.056a.49.49 0 0 1 .485-.456c.253 0 .451.206.437.457 0 0 .012-.109-.006.061a4.813 4.813 0 1 1-1.348-3.887v-.987a.458.458 0 1 1 .917.002v2.062a.459.459 0 0 1-.459.459H7.334a.458.458 0 1 1-.002-.917h.928z"
                            />
                          </g>
                        </svg>
                        Clear filters
                      </>
                    ),
                  }}
                />
              </div>

              <div className="clear-filters" data-layout="mobile">
                <ResultsNumberMobile />
              </div>
            </div>

            <div className="container-body">
              {/* <Panel header="Category">
                <HierarchicalMenu
                  attributes={[
                    'hierarchicalCategories.lvl0',
                    'hierarchicalCategories.lvl1',
                  ]}
                />
              </Panel> */}
              <Panel header="Category">
                <RefinementList
                  attribute="type"
                />
              </Panel>

              {/* <Panel header="Price">
                <PriceSlider attribute="price.value" />
              </Panel> */}

              <Panel header="Type">
                <RefinementList
                  attribute="master_trait"
                  searchable={true}
                   limit={5}
                  showMoreLimit={30}
                  showMore={true}
                  translations={{
                    placeholder: 'Cari Type',
                  }}
                />
              </Panel>

              <Panel header="Provinsi">
                <RefinementList
                  attribute="area.province"
                  searchable={true}
                   limit={5}
                  showMoreLimit={30}
                  showMore={true}
                  translations={{
                    placeholder: 'Cari Provinsi',
                  }}
                />
              </Panel>

              <Panel header="Kota">
                <RefinementList
                  attribute="area.city"
                  searchable={true}
                  limit={5}
                  showMoreLimit={30}
                  showMore={true}
                  translations={{
                    placeholder: 'Cari Kota',
                  }}
                />
              </Panel>

{/* 
              <Panel header="Luas Bangnan">
                <RefinementList
                  attribute="facilities.building_area"
                  searchable={true}
                  limit={5}
                  showMoreLimit={30}
                  showMore={true}
                  translations={{
                    placeholder: 'Luas Bangnan',
                  }}
                />
              </Panel> */}

              {/* <Panel header="Luas Tanah">
                <RefinementList
                  attribute="facilities.land_area"
                  searchable={true}
                  limit={5}
                  showMoreLimit={30}
                  showMore={true}
                  translations={{
                    placeholder: 'Luas Tanah',
                  }}
                />
              </Panel>

              <Panel header="Kamar Tidur">
                <RefinementList
                  attribute="facilities.bedroom"
                  searchable={true}
                  limit={5}
                  showMoreLimit={30}
                  showMore={true}
                  translations={{
                    placeholder: 'Kamar Tidur',
                  }}
                />
              </Panel> */}

              {/* <Panel header="Kamar Mandi">
                <RefinementList
                  attribute="facilities.bathroom"
                  searchable={true}
                  limit={5}
                  showMoreLimit={30}
                  showMore={true}
                  translations={{
                    placeholder: 'Kamar Mandi',
                  }}
                />
              </Panel> */}


              

              {/* <Panel header="Free shipping">
                <ToggleRefinement
                  attribute="free_shipping"
                  label="Display only items with free shipping"
                  value={true}
                />
              </Panel> */}

              {/* <Panel header="Ratings">
                <Ratings attribute="rating" />
              </Panel> */}
            </div>
          </section>

          <footer className="container-filters-footer" data-layout="mobile">
            <div className="container-filters-footer-button-wrapper">
              <ClearFiltersMobile containerRef={containerRef} />
            </div>

            <div className="container-filters-footer-button-wrapper">
              <SaveFiltersMobile onClick={closeFilters} />
            </div>
          </footer>
        </div>

        <section className="container-results">
          <header className="container-header container-options">
            {/* <SortBy
              className="container-option"
              defaultRefinement="house_listing"
              items={[
                {
                  label: 'Sort by price',
                  value: 'house_listing',
                },
                {
                  label: 'Price ascending',
                  value: 'house_listing.pr',
                },
                {
                  label: 'Price descending',
                  value: 'price.value',
                },
              ]}
            /> */}
            <HitsPerPage
              className="container-option"
              items={[
                {
                  label: '8 hits per page',
                  value: 8,
                },
                {
                  label: '16 hits per page',
                  value: 16,
                },
                {
                  label: '32 hits per page',
                  value: 32,
                },
              ]}
              defaultRefinement={9}
            />
          </header>

          <Box pb={2}>
            <Stats/>
          </Box>
          
          <Hits hitComponent={Hit} />
          <NoResults />

          <footer className="container-footer">
            <Pagination
              padding={2}
              showFirst={false}
              showLast={false}
              translations={{
                previous: (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                  >
                    <g
                      fill="none"
                      fillRule="evenodd"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.143"
                    >
                      <path d="M9 5H1M5 9L1 5l4-4" />
                    </g>
                  </svg>
                ),
                next: (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                  >
                    <g
                      fill="none"
                      fillRule="evenodd"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.143"
                    >
                      <path d="M1 5h8M5 9l4-4-4-4" />
                    </g>
                  </svg>
                ),
              }}
            />
          </footer>
        </section>
      </main>

      <aside data-layout="mobile">
        <button
          className="filters-button"
          data-action="open-overlay"
          onClick={openFilters}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 16 14">
            <path
              d="M15 1H1l5.6 6.3v4.37L9.4 13V7.3z"
              stroke="#fff"
              strokeWidth="1.29"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Filters
        </button>
      </aside>
    </InstantSearch>

    <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={openShare}
        onClose={handleCloseShare}
    >
        <DialogTitle>
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                <Box>Silahkan Share Link </Box>
                <Box>
                    <CloseIcon sx={{ color: 'red', cursor: 'pointer', fontSize: 30 }} onClick={handleCloseShare}/>
                </Box>
            </Stack>
        </DialogTitle>
        <DialogContent>
            <Stack flexDirection="row">
                <Box pr={1}>
                    <WhatsappShareButton
                        url={`https://groperti.com/detail/${shareUrl}?code=${userCredentials.code_referral}`}
                        title={`${shareTitle} ${sharePrice}`}
                        className="Demo__some-network__share-button"
                    >
                        <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                </Box>
                <Box pr={1}>
                    <TelegramShareButton
                        url={`https://groperti.com/detail/${shareUrl}?code=${userCredentials.code_referral}`}
                        title={`${shareTitle} ${sharePrice}`}
                        className="Demo__some-network__share-button"
                    >
                        <TelegramIcon size={32} round />
                    </TelegramShareButton>
                </Box>
                <Box pr={1}>
                    <FacebookShareButton
                        url={`https://groperti.com/detail/${shareUrl}?code=${userCredentials.code_referral}`}
                        quote={`${shareTitle} ${sharePrice}`}
                        hashtag={"#groperti #properti"}
                        className="Demo__some-network__share-button"
                    >
                        <FacebookIcon size={32} round />
                    </FacebookShareButton>
                </Box>
                <Box>
                    <TwitterShareButton
                        url={`https://groperti.com/detail/${shareUrl}?code=${userCredentials.code_referral}`}
                        title={`${shareTitle} ${sharePrice}`}
                        hashtags={["groperti", "properti"]}
                        className="Demo__some-network__share-button"
                    >
                        <TwitterIcon size={32} round />
                    </TwitterShareButton>
                </Box>
                <Box pl={1}>
                <CopyToClipboard 
                    text={`https://groperti.com/detail/${shareUrl}?code=${userCredentials.code_referral}`}
                    onCopy={() => {
                        setCopyTrue(true)
                        setTimeout(() => {
                            setCopyTrue(false)
                        }, 2000);
                    }}
                > 
                    <Box
                    sx={{ 
                        cursor: 'pointer', 
                        color: copyTrue ? 'green' : '#1455ef',
                        paddingTop: '2px'
                    }}
                    >
                      <img src={copyimage} width="30px" height="30px" alt="copy"/>
                    </Box>
                </CopyToClipboard>
              </Box>
            </Stack>
            { copyTrue ? 
            <Box py="1rem" color="green">Link Sukses di Copy!</Box> : null }
        </DialogContent>
    </Dialog>
  </>
  );
};

export default AlgoliaSearch