export const Step = () => {
    return (
      <div id="custom-font" className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 className="max-w-lg mb-6 text-3xl leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            Tiga Langkah Dapatkan Uang Tanpa Batas
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
            Ikuti ketiga langkah dibawah ini untuk mendapatkan uang. 
          </p>
        </div>
        <div className="grid gap-8 row-gap-8 lg:grid-cols-3">
          <div className="text-center">
            <div className="flex items-center text-center justify-center w-24 h-24 mb-4 text-5xl font-extrabold rounded-full text-deep-purple-accent-400 bg-indigo-50 sm:mx-auto">
              1
            </div>
            <h6 className="mb-2 font-semibold leading-5">Klik Untuk Berbagi</h6>
            <p className="max-w-md mb-3 text-sm text-gray-900 sm:mx-auto">
              Share satu properti yang menarik ke teman. 
            </p>
          </div>
          <div className="text-center">
            <div className="flex items-center text-center justify-center w-24 h-24 mb-4 text-5xl font-extrabold rounded-full text-deep-purple-accent-400 bg-indigo-50 sm:mx-auto">
              2
            </div>
            <h6 className="mb-2 font-semibold leading-5">Teman Kunjungi Website</h6>
            <p className="max-w-md mb-3 text-sm text-gray-900 sm:mx-auto">
              Teman yang mengunjungi website Groperti dari tautan share, akan mendapatkan
            </p>
          </div>
          <div className="text-center">
            <div className="flex items-center text-center justify-center w-24 h-24 mb-4 text-5xl font-extrabold rounded-full text-deep-purple-accent-400 bg-indigo-50 sm:mx-auto">
              3
            </div>
            <h6 className="mb-2 font-semibold leading-5">Saling Berbagi</h6>
            <p className="max-w-md mb-3 text-sm text-gray-900 sm:mx-auto">
              Teman yang diundang melalui tautan share oleh Anda, jika mengundang teman yang lain, Anda akan mendapatkan ekstra.
            </p>
          </div>
        </div>
      </div>
    );
  };