import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert'
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../utilities/config';

const initialState: any = {
  data: [], 
  loading : false,
  loaded : false,
  loading_sold : false,
  sold : []
};


export const getStatistikProperty = createAsyncThunk(
  'get/statistik-property',
  async (_, { rejectWithValue }) => { 
      try {
          const response : any = await axios.get(`${process.env.REACT_APP_API_DEV}/referral-data/statistic/${userCredentials.code_referral}`, {
              headers : {
                  Authorization : `Bearer ${userCredentials.id_token}`
              }
          })
          if(response.data.errors === null) {
              return  response.data.data
          } else {
              return rejectWithValue(response.data.message)
          }
        } catch (err : any) {
          if (!err.response) {
            throw err
          }
          return rejectWithValue(err.response)
      }
  }
);


export const getSoldProperty = createAsyncThunk(
  'get/sold-property',
  async (_, { rejectWithValue }) => { 
      try {
          const response : any = await axios.get(`${process.env.REACT_APP_API_DEV}/referral-data/code/${userCredentials.code_referral}`, {
            params : {
              withdrawStatus : "SOLD"
            },
            headers : {
                Authorization : `Bearer ${userCredentials.id_token}`
            }
          })
          if(response.data.errors === null) {
              return  response.data.data
          } else {
              return rejectWithValue(response.data.message)
          }
        } catch (err : any) {
          if (!err.response) {
            throw err
          }
          return rejectWithValue(err.response)
      }
  }
);


export const getStatistikReducer = createSlice({
  name: 'statistik',
  initialState, 
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get all statistik
      .addCase(getStatistikProperty.pending, (state) => {
        state.loading = true;
        state.loaded = false
      })
      .addCase(getStatistikProperty.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload;
        state.loaded = true
      })
      .addCase(getStatistikProperty.rejected, (state, action : any) => {
        state.loading = false;
        swal("Error", `${action.payload}`, 'error')
      })

      // get all data sold property
      .addCase(getSoldProperty.pending, (state) => {
        state.loading_sold = true;
      })
      .addCase(getSoldProperty.fulfilled, (state, action:any) => {
        state.loading_sold = false;
        state.sold = action.payload.referral_detail.length !== 0 ? action.payload.referral_detail : []
      })
      .addCase(getSoldProperty.rejected, (state, action : any) => {
        state.loading_sold = false;
        swal("Error", `${action.payload}`, 'error')
      })
  },
});


export default getStatistikReducer.reducer;
