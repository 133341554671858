import { useState } from "react";

export const Action = () => {
    const [valueForm, setValueForm] = useState("");

    const onClickDaftar = (e:any) => {
      e.preventDefault()
      localStorage.setItem('email_register', valueForm)
      window.location.href = "/register"
    }

    return (
      <div id="custom-font" className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-2xl mx-auto sm:max-w-xl md:max-w-2xl">
          <div className="text-center">
            <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
              <h2 className="max-w-lg mb-6 text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                Gabung Sekarang!
              </h2>
              <p className="text-base text-gray-700 md:text-lg">
                Daftarkan diri Anda sekarang dan bergabung dengan GroReferal.
              </p>
            </div>
            <form 
              className="flex flex-col items-center w-full mb-4 md:flex-row md:px-16"
              onSubmit={onClickDaftar}
            >
              <input
                placeholder="Email"
                required
                type="email"
                onChange={(e) => setValueForm(e.target.value)}
                className="flex-grow w-full h-12 px-4 mb-3 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none md:mr-2 md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
              />
              <button
                type="submit"
                className="inline-flex items-center justify-center w-full h-12 px-4 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-6/12 bg-red-500 focus:shadow-outline focus:outline-none"
              >
                Daftar Sekarang
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  };