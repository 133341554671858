import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert'
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../utilities/config';

const initialState: any = {
  data: [], 
  loading : false,
  create : false, 
  loading_create : false,
};

export const getWithdrawList = createAsyncThunk(
  'get/withdraw',
  async (_, { rejectWithValue }) => { 
      try {
          const response : any = await axios.get(`${process.env.REACT_APP_API_DEV}/withdrawal/referral/${userCredentials.code_referral}`, {
              headers : {
                  Authorization : `Bearer ${userCredentials.id_token}`
              }
          })
          if(response.data.errors === null) {
              return  response.data.data
          } else {
              return rejectWithValue(response.data.message)
          }
        } catch (err : any) {
          if (!err.response) {
            throw err
          }
          return rejectWithValue(err.response)
      }
  }
);


export const createActionWithdraw = createAsyncThunk(
  'post/withdraw',
  async (body:any, { rejectWithValue }) => { 
      try {
          const response : any = await axios.patch(`${process.env.REACT_APP_API_DEV}/referral-data/withdraw/${userCredentials.code_referral}`, body, {
            headers : {
                Authorization : `Bearer ${userCredentials.id_token}`
            }
          })
          if(response.data.errors === null) {
              return true
          } else {
              return rejectWithValue(response.data.message)
          }
        } catch (err : any) {
          if (!err.response) {
            throw err
          }
          return rejectWithValue(err.response)
      }
  }
);


export const getWithdrawReducer = createSlice({
  name: 'withdraw',
  initialState, 
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get all statistik
      .addCase(getWithdrawList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWithdrawList.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getWithdrawList.rejected, (state, action : any) => {
        state.loading = false;
        swal("Error", `${action.payload}`, 'error')
      })

      // get all data sold property
      .addCase(createActionWithdraw.pending, (state) => {
        state.loading_create = true;
      })
      .addCase(createActionWithdraw.fulfilled, (state, action:any) => {
        state.loading_create = false;
        state.create = action.payload;
        swal("Success", `Sukses menarik Uang, Silahkan lihat status penarikan di halaman Tarik Uang!`, 'success')
      })
      .addCase(createActionWithdraw.rejected, (state, action : any) => {
        state.loading_create = false;
        swal("Error", `${action.payload}`, 'error')
      })
  },
});


export default getWithdrawReducer.reducer;
