import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useHistory } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Box from '@mui/material/Box';
import { userCredentials } from '../utilities/config';
import { Stack } from '@mui/material';
// import Feedback from './Feedback';
import logo from '../assets/img/logo.png'
import logosmall from '../assets/img/logo-small.png'
import home from '../assets/img/icon/home.png'
import listing from '../assets/img/icon/product.png'
import user from '../assets/img/icon/user.png'
import register from '../assets/img/icon/register.png'
import chart from '../assets/img/icon/chart.png'
import salary from '../assets/img/icon/salary.png'
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import MuiDrawer from '@mui/material/Drawer';

const drawerWidth = 199;

const openedMixin = (theme: Theme): CSSObject => ({ 
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });
  
  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });
  
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));
  
  interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
  }
  

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );

interface Props {
    window?: () => Window;
  }

const NavDashboard = (props: Props) => {
    // const auth = useSelector((state : RootState) => state.login )

    const history = useHistory()

    const [dropDownOpen, setDropdownOpen] = React.useState({
        name : '',
        open : false
    });

    const handleDropDownMenu = (name : string) => {
        setDropdownOpen({...dropDownOpen, name : name, open : !dropDownOpen.open });
    };

    const [openDraw, setOpenDraw] = React.useState(false);
  
    const handleDrawerOpen = () => {
      setOpenDraw(true);
    };
  
    const handleDrawerClose = () => {
      setOpenDraw(false);
    };

    // const [mobileOpen, setMobileOpen] = React.useState(false);
        
    // dropdown menu 
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event : any) => {
        setAnchorEl(event.currentTarget);
    };

    /* istanbul ignore next */
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const open = Boolean(anchorEl);
    /* istanbul ignore next */
    const id = 2 ? 'simple-popover' : undefined;

    /* istanbul ignore next */
    const onClickSignOut = () : void => {
        localStorage.clear()
        history.go(0)
    }

    const data_navigation = [
        {
            id: 1,
            module_detail : {
                name : "Dashboard",
                path : '/dashboard',
                iconImage : home
            },
            child_navigations: []
        },
        {
            id: 2,
            module_detail : {
                name : "Properti Dibagikan",
                path : '/dashboard/propertyshared',
                iconImage : listing
            },
            child_navigations: []
        },
        {
            id: 3,
            module_detail : {
                name : "Statistik",
                path : '/dashboard/statistik',
                iconImage : chart
            },
            child_navigations: [ ]
        },
        {
            id: 4,
            module_detail : {
                name : "Tarik Uang",
                path : '/dashboard/withdraw',
                iconImage : salary
            },
            child_navigations: [ ]
        },
        {
            id: 5,
            module_detail : {
                name : "Profile",
                path : '/dashboard/profile',
                iconImage : user
            },
            child_navigations: []
        }
    ]

    const complete_register = [
        {
            id: 1,
            module_detail : {
                name : "Completing Register",
                path : '/completing-register',
                iconImage : register
            },
            child_navigations: []
        },
    ]

    const drawerComponent = (
        <div>   
            <div className="sidebar-navbar">
                { userCredentials.first_time ? 
                <div>
                    { complete_register.map((nav:any, index:any) => (
                    <div key={index}>
                    { nav.child_navigations.length === 0 ?
                    <>
                    <NavLink exact to={nav.module_detail.path} activeClassName="active-link" onClick={handleDrawerClose}>
                        <ul>
                            <li className="navbar-list">
                                <div className="icon">
                                    <Box
                                        component="img"
                                        src={nav.module_detail.iconImage}
                                        sx={{
                                            width: 20,
                                            height: 20,
                                        }}
                                    />
                                </div>
                                { openDraw ? <span>{nav.module_detail.name}</span> : null }
                            </li>
                        </ul>
                    </NavLink>
                    <Divider />
                    </>
                    : 
                    <>
                    <div className="sidebar-navbar-dropdown">
                        <div className="menu-dropdown" onClick={() => handleDropDownMenu(nav.module_detail.name)}>
                            <Stack flexDirection="row">
                                <div className="icon">
                                    <Box
                                        component="img"
                                        src={nav.module_detail.iconImage}
                                        sx={{
                                            width: 20,
                                            height: 20,
                                        }}
                                    />
                                </div>
                                <Box ml={1} mr={1}>
                                    { openDraw ? <span>{nav.module_detail.name}</span> : null }
                                </Box>
                                <Box>
                                    { dropDownOpen.name === nav.module_detail.name && dropDownOpen.open ? 
                                    <ArrowDropUpIcon/> : <ArrowDropDownIcon/>  }
                                </Box>
                            </Stack>
                        </div>
                        <div className={dropDownOpen.name === nav.module_detail.name && dropDownOpen.open ? 'menu-list-dropdown-active' : 'menu-list-dropdown'}>
                            { nav.child_navigations.map((child:any, index:any) => (
                            <div key={index}>
                                <Divider />
                                <NavLink exact to={child.path} activeClassName="active-link" >
                                    <ul>
                                        <li className="navbar-list">
                                            <div className="icon">
                                                <Box
                                                    component="img"
                                                    src={nav.module_detail.iconImage}
                                                    sx={{
                                                        width: 20,
                                                        height: 20,
                                                    }}
                                                />
                                            </div>
                                            { openDraw ? <span>{child.name}</span> : null }
                                        </li>
                                    </ul>
                                </NavLink>
                            </div>
                            ))}
                        </div>
                    </div>
                    <Divider />
                    </>
                    }
                    </div>
                    ))}
                </div> 
                :
                <div>
                    { data_navigation.map((nav:any, index:any) => (
                    <div key={index}>
                    { nav.child_navigations.length === 0 ?
                    <>
                    <NavLink exact to={nav.module_detail.path} activeClassName="active-link" onClick={handleDrawerClose}>
                        <ul>
                            <li className="navbar-list">
                                <div className="icon">
                                    <Box
                                        component="img"
                                        src={nav.module_detail.iconImage}
                                        sx={{
                                            width: 20,
                                            height: 20,
                                        }}
                                    />
                                </div>
                                { openDraw ? <span>{nav.module_detail.name}</span> : null }
                            </li>
                        </ul>
                    </NavLink>
                    <Divider />
                    </>
                    : 
                    <>
                    <div className="sidebar-navbar-dropdown">
                        <div className="menu-dropdown" onClick={() => handleDropDownMenu(nav.module_detail.name)}>
                            <Stack flexDirection="row">
                                <div className="icon">
                                    <Box
                                        component="img"
                                        src={nav.module_detail.iconImage}
                                        sx={{
                                            width: 20,
                                            height: 20,
                                        }}
                                    />
                                </div>
                                <Box ml={1} mr={1}>
                                    { openDraw ? <span>{nav.module_detail.name}</span> : null }
                                </Box>
                                <Box>
                                    { dropDownOpen.name === nav.module_detail.name && dropDownOpen.open ? 
                                    <ArrowDropUpIcon/> : <ArrowDropDownIcon/>  }
                                </Box>
                            </Stack>
                        </div>
                        <div className={dropDownOpen.name === nav.module_detail.name && dropDownOpen.open ? 'menu-list-dropdown-active' : 'menu-list-dropdown'}>
                            { nav.child_navigations.map((child:any, index:any) => (
                            <div key={index}>
                                <Divider />
                                <NavLink exact to={child.path} activeClassName="active-link" onClick={handleDrawerClose}>
                                    <ul>
                                        <li className="navbar-list">
                                            <div className="icon">
                                            <Box
                                                component="img"
                                                src={nav.module_detail.iconImage}
                                                sx={{
                                                    width: 20,
                                                    height: 20,
                                                }}
                                            />
                                            </div>
                                            { openDraw ? <span>{child.name}</span> : null }
                                        </li>
                                    </ul>
                                </NavLink>
                            </div>
                            ))}
                        </div>
                    </div>
                    <Divider />
                    </>
                    }
                    </div>
                    ))}
                </div> 
                }
                {/* <Feedback disabled={userCredentials.first_time ? true : false} openDraw={openDraw} /> */}
            </div>
    
        </div>
      );

    return (
        <div>
            <CssBaseline />
            <AppBar
                position="fixed"
                open={openDraw}
            >
                <Toolbar>
                    <Box 
                        className="logo-mpi-p" 
                        sx={{
                            ...(openDraw && { display: 'none' }),
                        }}
                    >
                        <a href="/dashboard">
                            <img 
                                alt="logo mpi dashboard" 
                                src={logosmall}
                            />
                        </a>
                    </Box>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            ...(openDraw && { display: 'none' }),
                        }}
                    >
                        <DoubleArrowIcon sx={{color:'#fff'}} />
                    </IconButton>
                    <div style={{flexGrow: 1}} />
                    <Box>
                        <div 
                            className="right-navbar"  
                            data-testid="dropdownButton"
                            onClick={handleClick}
                        >
                            <Box> <AccountCircleIcon/>  </Box>
                            <Box pl={1}>{userCredentials.name}</Box>
                            <Box pl={1}> <ArrowDropDownIcon/></Box>
                        </div>
                        {/* Dropdown Menu */}
                        <Popover
                            data-testid="dropdown"
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            className="popper-style"
                        >
                        <Box>
                            <div style={{ width: 150 }}>
                            <List component="nav" aria-label="secondary mailbox folders">
                                <ListItem 
                                    button 
                                    onClick={() => {
                                        /* istanbul ignore next */
                                        onClickSignOut()
                                    }}>
                                    <ListItemText className="btn-navlist" primary="Sign Out" />
                                </ListItem>
                            </List>
                            </div>
                        </Box>
                        </Popover>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box display={openDraw ? "block" : "none"}>
                <Drawer
                    variant="permanent"
                    open={openDraw}
                >
                    <DrawerHeader sx={{ backgroundColor: '#2d2a6d' }}>
                        <Toolbar>
                            <div className="logo-mpi">
                                <a href="/dashboard">
                                    <img 
                                        alt="logo gro dashboard" 
                                        src={logo}
                                    />
                                </a>
                            </div>
                        </Toolbar>
                        <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon sx={{color:'#fff'}} />
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    {drawerComponent}
                </Drawer>
            </Box>
        </div>
    )
}

export default NavDashboard;
