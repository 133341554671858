import { Stack } from '@mui/material'
import { Box } from '@mui/system'
import AlgoliaSearch from '../../../components/search_property/AlgoliaSearch'
import {userCredentials} from './../../../utilities/config'

const username = userCredentials === null ? "" : userCredentials.name

function HomeDashboard() {
    
    return (
        <Box sx={{p:2}}>
            <Box>
                <h2 className='font-bold text-xl selamat-datang'>Selamat datang, {username}</h2>
                <Box pt={1}>
                    <p>Yuk Mulai bagikan properti dibawah ini untuk mendapatkan Reward!</p>
                </Box>
            </Box>

            <Stack sx={{pt:2}}  >
                <AlgoliaSearch/>
            </Stack>
        </Box>
    )
}

export default HomeDashboard
