import { createAsyncThunk } from '@reduxjs/toolkit';
import crypto from 'crypto-js'; 
import Axios from 'axios'
import {
    InputState
} from '../loginTypes'

function checkRole (role:any, roles:any) {
    return roles.includes(role)
}

export const loginAction = createAsyncThunk(
    'auth/login',
    async (value : InputState , { rejectWithValue }) => {
 
        const body = {
            email : value.email,
            password : value.password,
            last_login_location  : ""
        }
        try {
            const response : any = await Axios.post(`${process.env.REACT_APP_API_AUTH}/api/login`, body)
            if(response.status === 200) {
                if(checkRole('INFLUENCER', response?.data?.flag)) {
                    const profile : any = await Axios.get(`${process.env.REACT_APP_API_DEV}/influencer/${response.data.auth_id}`)
                    if(profile.status === 200) {
                        const first_time = profile.data.data.profile === null ? true : false
                        let data = {
                            access_token : response?.data?.token,
                            id_token : response?.data?.token, 
                            email :response?.data?.email,
                            name : response?.data?.name, 
                            flag : response?.data?.flag, 
                            avatar : "",
                            auth_id : response?.data?.auth_id,
                            first_time : first_time,
                            code_referral : profile?.data?.data?.profile?.referral_code || "",
                            login: true
                        }
                        const saveToLocalStorage = crypto.AES.encrypt(JSON.stringify(data), `${process.env.REACT_APP_CRYPTO_SECRET}`).toString();
                        localStorage.setItem('_?GroRefcredentials', saveToLocalStorage)
                        return data
                    }
                } else {
                    let body_reg = {
                        token : response?.data?.token,
                        flag : ['INFLUENCER'],
                        name : response?.data?.name
                    }
                    const register_flag : any = await Axios.put(`${process.env.REACT_APP_API_AUTH}/api/user/update`, body_reg, {
                        headers : {
                            'Authorization' : `Bearer ${response?.data?.token}`
                        }
                    })
                    if(register_flag.status === 200) {
                        const profile : any = await Axios.get(`${process.env.REACT_APP_API_DEV}/influencer/${response.data.auth_id}`)
                        if(profile.status === 200) {
                            const first_time = profile.data.data.profile === null ? true : false
                            let data = {
                                access_token : response?.data?.token,
                                id_token : response?.data?.token, 
                                email :response?.data?.email,
                                name : response?.data?.name, 
                                flag : response?.data?.flag, 
                                avatar : "",
                                auth_id : response?.data?.auth_id,
                                code_referral : profile?.data?.data?.profile?.referral_code || "",
                                first_time : first_time,
                                login: true
                            }
                            const saveToLocalStorage = crypto.AES.encrypt(JSON.stringify(data), `${process.env.REACT_APP_CRYPTO_SECRET}`).toString();
                            localStorage.setItem('_?GroRefcredentials', saveToLocalStorage)
                            return data
                        }
                    }
                }
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            if (!err.response) {
              throw err
            }
            return rejectWithValue(err.response.data.errors || "Internal server error")
        }
    }
  );


  export const loginGoogleAction = createAsyncThunk(
    'auth-google/login',
    async (token : string , { rejectWithValue }) => {
 
        const body = {
            idToken : token,
            flag : ['INFLUENCER']
          }
        try {
            const response : any = await Axios.post(`${process.env.REACT_APP_API_AUTH}/api/googlelogin`, body)
            if(response.status === 200) {
                if(checkRole('INFLUENCER', response?.data?.flag)) {
                    const profile : any = await Axios.get(`${process.env.REACT_APP_API_DEV}/influencer/${response.data.auth_id}`)
                    if(profile.status === 200) {
                        const first_time = profile.data.data.profile === null ? true : false
                        let data = {
                            access_token : response?.data?.token,
                            id_token : response?.data?.token, 
                            email :response?.data?.email,
                            name : response?.data?.name, 
                            flag : response?.data?.flag, 
                            avatar : "",
                            auth_id : response?.data?.auth_id,
                            first_time : first_time,
                            code_referral : profile?.data?.data?.profile?.referral_code || "",
                            login: true
                        }
                        const saveToLocalStorage = crypto.AES.encrypt(JSON.stringify(data), `${process.env.REACT_APP_CRYPTO_SECRET}`).toString();
                        localStorage.setItem('_?GroRefcredentials', saveToLocalStorage)
                        return data
                    }
                } else {
                    let body_reg = {
                        token : response?.data?.token,
                        flag : ['INFLUENCER'],
                        name : response?.data?.name
                    }
                    const register_flag : any = await Axios.put(`${process.env.REACT_APP_API_AUTH}/api/user/update`, body_reg, {
                        headers : {
                            'Authorization' : `Bearer ${response?.data?.token}`
                        }
                    })
                    if(register_flag.status === 200) {
                        const profile : any = await Axios.get(`${process.env.REACT_APP_API_DEV}/influencer/${response.data.auth_id}`)
                        if(profile.status === 200) {
                            const first_time = profile.data.data.profile === null ? true : false
                            let data = {
                                access_token : response?.data?.token,
                                id_token : response?.data?.token, 
                                email :response?.data?.email,
                                name : response?.data?.name, 
                                flag : response?.data?.flag, 
                                avatar : "",
                                auth_id : response?.data?.auth_id,
                                code_referral : profile?.data?.data?.profile?.referral_code || "",
                                first_time : first_time,
                                login: true
                            }
                            const saveToLocalStorage = crypto.AES.encrypt(JSON.stringify(data), `${process.env.REACT_APP_CRYPTO_SECRET}`).toString();
                            localStorage.setItem('_?GroRefcredentials', saveToLocalStorage)
                            return data
                        }
                    }
                }
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            if (!err.response) {
              throw err
            }
            return rejectWithValue(err.response.data.errors || "Internal server error")
        }
    }
  );