import { Stack, Box, Button, Paper, TextField } from '@mui/material'
import {useState, useEffect} from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { getProfileCompany, postBankAccount, } from '../reducers/profilesReducers';
import swal from 'sweetalert';

const BankAccount = ({ data } : any) => {

    const dispatch = useDispatch()
    const store_profile = useSelector((state : RootState) => state.profile)

    const [open, setOpen] = useState({
        open : false, 
        name : ""
    });

    const [valueBank, setValueBank] = useState({
        bank_number : "",
        bank_name : "",
        bank_account: ""
    });

    const onChangeValue = (e:any) => {
        setValueBank({...valueBank, [e.target.name]: e.target.value })
    }

    const handleClose = () => {
        setOpen({...open, open: false, name : "" })
    }

    const handleOpen = (name : any) => {
        setOpen({...open, open: true, name : name })
    }

    const onClickSubmit = (e : any) => {
        e.preventDefault()
        let body_send = {
            bank : {
                "bank_name": valueBank.bank_name,
                "bank_number": valueBank.bank_number,
                "bank_account":valueBank.bank_account,
            }
        }
        dispatch(postBankAccount(body_send))
    }

    useEffect(() => {
        if(store_profile.bank_post) {
            handleClose()
            setValueBank({...valueBank, 
                bank_number : "",
                bank_name : "",
                bank_account: ""
            })
            swal('Success', 'Success Add Bank Account', 'success')
            dispatch(getProfileCompany())
        }
        // eslint-disable-next-line
    }, [store_profile.bank_post])

    return (
        <Stack mt={1}>
            <Stack flexDirection="row" justifyContent="space-between">
                <Box>
                    <Box fontWeight="700" fontSize={22}>Rekening Bank </Box>
                </Box>
                {  data.profile.bank === undefined ?
                <Box>
                    <Button
                        variant='contained'
                        size="small"
                        color="primary"
                        onClick={() => handleOpen('Create New')}
                    >Tambah Rekening
                    </Button>
                </Box> : null }
            </Stack>

            { store_profile.loading ? 
            <Box pt={3}>Loading...</Box>
            :
            <Stack>
                { data.profile.bank === undefined ? 
                <Stack mt={2}>
                    <Paper elevation={3} sx={{ border: '#023047 2px solid' }}>
                        <Box p={3}>
                            <Box pt={1} fontWeight="700">
                                Kamu belum menambahkan rekening bank
                            </Box>
                        </Box>
                    </Paper>
                </Stack> 
                :  
                <Stack>
                <Stack mt={2} >
                    <Paper elevation={3} sx={{ border: '#0091d6 2px solid' }}>
                        <Box p={3}>
                            <Box fontWeight="400">{ data.profile.bank.bank_name}</Box>
                            <Box pt={1} fontWeight="700">
                                { data.profile.bank.bank_number}
                            </Box>
                            <Box pt={1} fontWeight="500">
                                an { data.profile.bank.bank_account}
                            </Box>
                        </Box>
                    </Paper>
                </Stack> 
                </Stack> }
            </Stack> }

            <Dialog open={open.open} maxWidth="md" fullWidth={true}  >
                <form onSubmit={onClickSubmit}>
                    <DialogTitle>{open.name}</DialogTitle>
                    <DialogContent>
                        <Stack>
                            <TextField 
                                label="Nama Bank"
                                placeholder='BCA'
                                onChange={onChangeValue}
                                name="bank_name"
                                type="text"
                                margin='dense'
                            />
                            <TextField 
                                label="Nomor Rekening"
                                placeholder='2100-00-00-00'
                                onChange={onChangeValue}
                                name="bank_number"
                                type="number"
                                margin='dense'
                            />
                            <TextField 
                                label="Nama Pemilik Rekening"
                                placeholder='John Doe'
                                onChange={onChangeValue}
                                name="bank_account"
                                type="text"
                                margin='dense'
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="error">Batal</Button>
                        <Button variant="contained" type="submit">
                            { store_profile.loading_bank_post ? "Loading" : "Tambah" } 
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Stack>
    )
}

export default BankAccount
