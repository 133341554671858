import { useEffect, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { Box } from '@mui/system'
import { TableColumn } from 'react-data-table-component';
import BreadCrumbs from '../../../components/BreadCrumbs'
import DataTableBase from '../../../components/TableData'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { getWithdrawList } from './withdrawSlice';
import moment from 'moment'

const WithdrawPage = () => {

    const dispatch = useDispatch()
    const {data, loading} = useSelector((state : RootState) => state.withdraw)

    const [dataDetail, setDataDetail] = useState<any>([]);
    const [loaded, setLoaded] = useState(false);

    const [limit, setLimit] = useState(15);
    const [page, setPage] = useState(1);

    const [openView, setOpenView] = useState(false);

    const handleCloseView = () => {
        setOpenView(false);
    };

    useEffect(() => {
        dispatch(getWithdrawList())
        // eslint-disable-next-line
    }, []);

    const columns: TableColumn<any>[] = [
        {
            name: 'NO',
            width: '80px',
            cell: (row, index) => (
                <p>
                { page > 1 ? 
                    <>
                    {index + 1 + (limit * (page-1))}
                    </>
                    :
                    <> 
                    {index + 1}
                    </>
                }
                </p>
            )
        },
        {
            name: 'Tanggal Request',
            cell: (row) => (
                <div>{moment(row.request_date).format('LL')}</div>
            )
        },
        {
            name: 'Bank',
            cell: (row) => (
                <div>{row.bank_name}</div>
            )
        },
        {
            name: 'No Rekening',
            cell: (row) => (
                <div>{row.bank_number}</div>
            )
        },
        {
            name: 'Penerima',
            cell: (row) => (
                <div>{row.bank_account}</div>
            )
        },
        {
            name: 'Total',
            cell: (row) => (
                <div>Rp. {row.total.toLocaleString()}</div>
            )
        },
        {
            name: 'Status',
            cell: (row) => (
                <div>{row.status === "REQUEST" ? "Diproses" : "Terbayar"}</div>
            )
        },
        {
            name: 'Action',
            width: '200px',
            cell: (row ) => (
                <Stack direction="row" spacing={2}>
                    <Button 
                        disabled={row.payment_proof.url_image === "" ? true : false}
                        variant="outlined" color="primary" size="small"
                        onClick={() => {
                            setDataDetail(row)
                            setLoaded(true)
                            setOpenView(true)
                        }}
                    >
                     Lihat Bukti
                    </Button>
                </Stack>
            ),
        },
    ];

    return (
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs
                isPage={false}
                current="Tarik Uang"
            />
            <Stack direction="row" justifyContent="space-between" pt={3} >
                <Box>
                    <h1>Status Tarik Uang</h1>
                </Box>
            </Stack>
            <Box sx={{pt:1}}>
                <DataTableBase 
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    pagination
                    onChangePage={(value) => setPage(value)}
                    onChangeRowsPerPage={(value) => setLimit(value)}
                    paginationPerPage={limit}
                    noDataComponent="Beluma ada data! Kamu bisa menarik uang dari halaman statistik."
                    persistTableHead
                />
            </Box>

            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={openView}
                onClose={handleCloseView}
            >
                <DialogTitle>
                    <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Box>Detail Bukti Pemabyaran</Box>
                        <Box>
                            <CloseIcon sx={{ color: 'red', cursor: 'pointer', fontSize: 30 }} onClick={handleCloseView}/>
                        </Box>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Box>Tanggal Dibayar  : {moment(loaded && dataDetail.payment_proof.created_at).format("LLL")}</Box>
                        <Box py={2}>
                            <img src={loaded && dataDetail.payment_proof.url_image}  alt="bukti" width="80%"/>
                        </Box>
                    </Box>

                </DialogContent>
            </Dialog>

        </Box>
    )
}

export default WithdrawPage
