import { useEffect, useState } from 'react';
import { Button, Grid, Paper, Skeleton, Stack } from '@mui/material';
import { Box } from '@mui/system'
import { TableColumn } from 'react-data-table-component';
import BreadCrumbs from '../../../components/BreadCrumbs'
import DataTableBase from '../../../components/TableData'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { userCredentials } from '../../../utilities/config';
import { getSoldProperty, getStatistikProperty } from './statistikSlice';
import { createActionWithdraw } from '../withdraw/withdrawSlice';

const StatistikPage = () => {

    const dispatch = useDispatch()
    const { data, loading, loaded, sold, loading_sold } = useSelector((state : RootState) => state.statistik)
    const { create, loading_create } = useSelector((state : RootState) => state.withdraw)

    const [limit, setLimit] = useState(15);
    const [page, setPage] = useState(1);

    useEffect(() => {
        dispatch(getStatistikProperty())
        dispatch(getSoldProperty())
        // eslint-disable-next-line
    }, [create]);

    const getSoldByIdProperty = (data:any) => {
        let ids = []
        for(let itm of data) {
            if(itm.withdrawStatus === "SOLD" && itm.house_detail.soldByReferral === userCredentials.code_referral) {
                ids.push(itm.house_detail._id)
            }
        }
        return ids
    }

    const onClickWithdraw = () => {
        let data_send = {
            withdrawStatus : "REQUEST",
            house_ids : getSoldByIdProperty(sold),
            total : loaded && data.total_income,
        }
        dispatch(createActionWithdraw(data_send))
    }


    const columns: TableColumn<any>[] = [
        {
            name: 'NO',
            width: '70px',
            cell: (row, index) => (
                <p>
                { page > 1 ? 
                    <>
                    {index + 1 + (limit * (page-1))}
                    </>
                    :
                    <> 
                    {index + 1}
                    </>
                }
                </p>
            )
        },
        {
            name: 'NAME',
            width: '350px',
            cell: (row) => (
                <Box pl={1} pt={1} sx={{ textTransform : 'capitalize', color: 'blue' }}>
                    <a href={`https://groperti.com/detail/${row.house_detail.slug}`} rel="noreferrer" target="_blank" >{row.house_detail.title}</a>
                </Box>
            )
        },
        {
            name: 'PRICE',
            cell: (row) => (
                <p>Rp. {row.house_detail.price.title}</p>
                
            )
        },
        {
            name: 'Type Property',
            cell: (row) => (
                <Box sx={{ textTransform : 'capitalize' }} >{row.house_detail.type} di {row.house_detail.sell_type}</Box>
            )
        },
        {
            name: 'Total Reward',
            cell: (row) => (
                <p>Rp. {row.house_detail.shared_max_rupiah.toLocaleString()}</p>
            )
        },
        {
            name: 'Viewer',
            cell: (row) => (
                <p>{row.clicked_times}</p>
            )
        },
        {
            name: 'Reward You Got',
            cell: (row) => (
                <p>Rp. {row.total_rupiah.toLocaleString()}</p>
            )
        },
        {
            name: 'Status Property',
            cell: (row) => (
                <Box>
                    { row.house_detail.status === "SOLD" && row.house_detail.soldByReferral === userCredentials.code_referral ? 
                    <p>{row.house_detail.status} by You</p> : row.house_detail.status === "SOLD" && row.house_detail.soldByReferral !== userCredentials.code_referral ? 
                    <p>{row.house_detail.status} by Other Platform</p> : <p>{row.house_detail.status}</p> }
                </Box>
            )
        },
        // {
        //     name: 'Sold By',
        //     cell: (row) => (
        //         <Box>
        //             Others / Groperti
        //         </Box>
        //     )
        // },
    ];

    return (
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs
                isPage={false}
                current="Statistik"
            />
            { loading ? 
            <Stack sx={{ mt: 3 }}>
                <Box mb={3}>
                    <Box pb={2} fontSize="20px">Total Uang yang bisa Ditarik</Box>
                    <Grid container spacing={3}>
                        <Grid item xl={3} lg={3} sm={12} md={6} xs={12} >
                            <Paper>
                                <Skeleton height={110}/>
                            </Paper>
                        </Grid>
                        <Grid item xl={3} lg={3} sm={12} md={6} xs={12} >
                            <Paper>
                                <Skeleton height={110}/>
                            </Paper>
                        </Grid>
                        <Grid item xl={3} lg={3} sm={12} md={12} xs={12} >
                            <Paper>
                                <Skeleton height={110}/>
                            </Paper>
                        </Grid>
                        <Grid item xl={3} lg={3} sm={12} md={12} xs={12} >
                            <Paper>
                                <Skeleton height={110}/>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
                <Box mb={3}>
                    <Box pb={2} fontSize="20px">Statistik Semua Properti Di bagikan</Box>
                    <Grid container spacing={3}>
                        <Grid item xl={3} lg={3} sm={12} md={6} xs={12} >
                            <Paper>
                                <Skeleton height={110}/>
                            </Paper>
                        </Grid>
                        <Grid item xl={3} lg={3} sm={12} md={6} xs={12} >
                            <Paper>
                                <Skeleton height={110}/>
                            </Paper>
                        </Grid>
                        <Grid item xl={3} lg={3} sm={12} md={12} xs={12} >
                            <Paper>
                                <Skeleton height={110}/>
                            </Paper>
                        </Grid>
                        <Grid item xl={3} lg={3} sm={12} md={12} xs={12} >
                            <Paper>
                                <Skeleton height={110}/>
                            </Paper>
                        </Grid>
                </Grid>
                </Box>
            </Stack>
            :
            <Stack sx={{ mt: 3 }} className='layout-box'>
                <Box mb={3}>
                    <Box pb={2} fontSize="20px">Total Uang yang bisa Ditarik</Box>
                    <Grid container spacing={3}>
                        <Grid item xl={3} lg={3} sm={3} md={3} xs={12} >
                            <Paper>
                                <Stack sx={{p:2}} flexDirection="column" justifyContent="space-between" alignItems="center">
                                    <Box fontSize={13} pr={1} >
                                        Total Reward Fixed
                                    </Box>
                                    <Box fontSize={30} color="green">
                                        Rp. { loaded && data.total_income_by_comission_sold.toLocaleString()}
                                    </Box>
                                    <Box fontSize="12px">Dari Properti yang dibagikan terjual</Box>
                                </Stack>
                            </Paper>
                        </Grid>
                        <Grid item xl={3} lg={3} sm={3} md={3} xs={12} >
                            <Paper>
                                <Stack sx={{p:2}} flexDirection="column" justifyContent="space-between" alignItems="center">
                                    <Box fontSize={13} pr={1} >
                                        Total Reward Klik
                                    </Box>
                                    <Box fontSize={30} color="green">
                                        Rp. { loaded && data.total_income_by_click_sold.toLocaleString()}
                                    </Box>
                                    <Box fontSize="12px">Dari Properti yang dibagikan terjual</Box>
                                </Stack>
                            </Paper>
                        </Grid>
                        <Grid item xl={3} lg={3} sm={3} md={3} xs={12} >
                            <Paper>
                                <Stack sx={{p:2}} flexDirection="column" justifyContent="space-between" alignItems="center">
                                    <Box fontSize={13} pr={1} >
                                        Total All Reward
                                    </Box>
                                    <Box fontSize={30} color="red">
                                        Rp. { loaded && data.total_income.toLocaleString()}
                                    </Box>
                                    <Box fontSize="12px">Dari Properti yang dibagikan terjual</Box>
                                </Stack>
                            </Paper>
                        </Grid>
                        <Grid item xl={3} lg={3} sm={3} md={3} xs={12} >
                            <Paper>
                                <Stack sx={{p:2}} flexDirection="column" justifyContent="center" alignItems="center" alignSelf="center" >
                                    <Box fontSize={13} pr={1} pt={1.5} >
                                        <Button 
                                            variant='contained'
                                            disabled={loaded && data.total_income < 100000 ? true : false}    
                                            onClick={onClickWithdraw}
                                        >
                                            {loading_create ? "Loading.." : "Withdraw"}
                                        </Button>
                                    </Box>
                                    <Box pt={2} fontSize="12px">Min. Total reward yang bisa di tarik Rp.100.000</Box>
                                </Stack>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
                <Box mb={3}>
                    <Box pb={2} fontSize="20px">Statistik Semua Properti Di bagikan</Box>
                    <Grid container spacing={3}>
                        <Grid item xl={3} lg={3} sm={3} md={3} xs={12} >
                            <Paper>
                                <Stack sx={{p:2}} flexDirection="column" justifyContent="space-between" alignItems="center">
                                    <Box fontSize={13} pr={1} >
                                        Total Semua Properti Dibagikan
                                    </Box>
                                    <Box fontSize={30} color="#0091d6">
                                        { loaded && data.total_property_shared.toLocaleString()}
                                    </Box>
                                    <Box fontSize="12px">Jumlah properti yang dibagikan</Box>
                                </Stack>
                            </Paper>
                        </Grid>
                        <Grid item xl={3} lg={3} sm={3} md={3} xs={12} >
                            <Paper>
                                <Stack sx={{p:2}} flexDirection="column" justifyContent="space-between" alignItems="center">
                                    <Box fontSize={13} pr={1} >
                                        Total Semua Klik
                                    </Box>
                                    <Box fontSize={30} color="#0091d6">
                                        { loaded && data.total_clicked.toLocaleString()}
                                    </Box>
                                    <Box fontSize="12px">
                                        Dari 
                                        <span style={{color: '#0091d6', fontSize: 15}}> <strong>{loaded && data.total_property_shared}</strong> </span> 
                                        properti yang dibagikan
                                    </Box>
                                </Stack>
                            </Paper>
                        </Grid>
                        <Grid item xl={3} lg={3} sm={3} md={3} xs={12} >
                            <Paper>
                                <Stack sx={{p:2}} flexDirection="column" justifyContent="space-between" alignItems="center">
                                    <Box fontSize={13} pr={1} >
                                        Potensi Total Reward Klik
                                    </Box>
                                    <Box fontSize={30} color="#0091d6">
                                        Rp. { loaded && data.total_income_by_click.toLocaleString()}
                                    </Box>
                                    <Box fontSize="12px">Dari total per klik  
                                    <span style={{color: '#0091d6', fontSize: 15}}> <strong>{loaded && data.total_property_shared}</strong> </span> 
                                    properti yang dibagikan</Box>
                                </Stack>
                            </Paper>
                        </Grid>
                        <Grid item xl={3} lg={3} sm={3} md={3} xs={12} >
                            <Paper>
                                <Stack sx={{p:2}} flexDirection="column" justifyContent="space-between" alignItems="center">
                                    <Box fontSize={13} pr={1} >
                                        Potensi Total Reward Fixed
                                    </Box>
                                    <Box fontSize={30} color="#0091d6">
                                        Rp. { loaded && data.total_income_by_comission.toLocaleString()}
                                    </Box>
                                    <Box fontSize="12px">Dari reward 
                                        <span style={{color: '#0091d6', fontSize: 15}}> <strong>{loaded && data.total_property_shared}</strong> </span> 
                                        properti yang dibagikan
                                    </Box>
                                </Stack>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Box 
                        pt={2} fontStyle="italic" fontSize="13px"
                        color="red"
                    >Note : Potensi Total reward klik dan Total reward fixed hanya akan kamu dapatkan jika properti yang kamu share terjual oleh code referal kamu.</Box>
                </Box>
            </Stack>
            }
            <Box pb={2} pt={2} fontSize="20px">Properti Dibagikan Terjual ({sold.length})</Box>
            <Box sx={{pt:1, mb:3}}>
                <DataTableBase 
                    columns={columns}
                    data={sold}
                    progressPending={loading_sold}
                    pagination
                    onChangePage={(value) => setPage(value)}
                    onChangeRowsPerPage={(value) => setLimit(value)}
                    paginationPerPage={limit}
                    noDataComponent="Belum ada properti yang kamu bagikan terjual."
                    persistTableHead
                />
            </Box>
        </Box>
    )
}

export default StatistikPage
